import { Avatar, Box, Button, Card, Skeleton, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  createMagicQuestion,
  getMagicQuestion,
  getResume,
  getResumeHistory,
  getResumeSpeeches,
  listAllMagicQuestion,
} from "utils/services";
import { LoadGif, Magic } from "static/images";
import Markdown from "react-markdown";
import { Input } from "components/input";
import { PageContainer, PageContainerToolbar } from "@toolpad/core";
import { AccountTree, ShoppingCart } from "@mui/icons-material";
import Loading from "components/Loading";
import "./styles.scss";

interface Speaker {
  name: string;
  id: number;
  avatar_url: string;
}

interface ResumeType {
  status: "loading" | "completed" | "error" | "blocked";
  name: string | undefined;
  created_at: string | undefined;
  resume: string | undefined;
  speakers: Speaker[] | undefined;
}

interface History {
  code: string;
  created_at: string;
  id: string;
}

interface ResumeHistory {
  status: "loading" | "completed" | "error";
  total: number | undefined;
  items: History[] | undefined;
}

interface Speech {
  name: string | undefined;
  content: string | undefined;
  spokeAt: string | undefined;
  avatarUrl: string | undefined;
}
interface ResumeSpeeches {
  status: "loading" | "completed" | "error";
  speeches: Speech[] | undefined;
}

interface AutomationQuestion {
  name: string;
  public_id: string;
}

interface MagicQuestion {
  status: "idle" | "loading" | "completed" | "error";
  answer: string | undefined;
  questionId: string | undefined;
  question: string | undefined;
  automation?: AutomationQuestion;
}

interface ListAllMagicQuestion {
  status: "idle" | "loading" | "completed" | "error";
  items: MagicQuestion[];
}

interface PageToolbarProps {
  date: string;
}

const PageToolbar: FC<PageToolbarProps> = ({ date }) => {
  return (
    <PageContainerToolbar>
      <p className="date">Data: {date}</p>
    </PageContainerToolbar>
  );
};

const ResumePage = () => {
  let { meetingId } = useParams();
  const [resume, setResume] = useState<ResumeType>({
    status: "loading",
    name: undefined,
    created_at: undefined,
    resume: undefined,
    speakers: undefined,
  });
  const [resumeHistory, setResumeHistory] = useState<ResumeHistory>({
    status: "loading",
    total: undefined,
    items: undefined,
  });
  const [resumeSpeeches, setResumeSpeeches] = useState<ResumeSpeeches>({
    status: "loading",
    speeches: undefined,
  });
  const [magicQuestion, setMagicQuestion] = useState<MagicQuestion>({
    status: "idle",
    answer: undefined,
    questionId: undefined,
    question: undefined,
  });

  const [allMagicQuestions, setAllMagicQuestions] =
    useState<ListAllMagicQuestion>({
      status: "idle",
      items: [],
    });
  const [question, SetQuestion] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (meetingId) {
      getResume(meetingId)
        .then((response) => {
          setResume({
            status: "completed",
            name: response.data.name,
            created_at: response.data.created_at,
            resume: response.data.resume,
            speakers: response.data.speakers,
          });

          getResumeHistory(meetingId || "", 0).then((response) => {
            setResumeHistory({
              status: "completed",
              total: response.data.total,
              items: response.data.items,
            });
          });

          getResumeSpeeches(meetingId || "").then((response) => {
            setResumeSpeeches({
              status: "completed",
              speeches: response.data.speeches.map((item: any) => ({
                name: item.name,
                content: item.content,
                spokeAt: item.spoke_at,
                avatarUrl: item.avatar_url,
              })),
            });
          });
        })
        .catch((error) => {
          if (error.status === 403) {
            setResume((old) => ({ ...old, status: "blocked" }));
          }
        });
    }
  }, [meetingId]);

  const runGetMagicQuestion = (questionId: string) => {
    getMagicQuestion(questionId)
      .then((response) => {
        if (response.data.status === "finished") {
          setMagicQuestion((old) => ({
            ...old,
            status: "completed",
            answer: response.data.answer,
            question: response.data.question,
          }));
        } else {
          setTimeout(() => runGetMagicQuestion(questionId), 1500);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const runMagicQuestion = () => {
    if (meetingId && question) {
      setMagicQuestion((old) => ({ ...old, status: "loading" }));
      createMagicQuestion(meetingId, question).then((response) => {
        setMagicQuestion((old) => ({
          ...old,
          questionId: response.data.question_id,
        }));
        setTimeout(() => runGetMagicQuestion(response.data.question_id), 1500);
      });
    }
  };

  useEffect(() => {
    if (meetingId) {
      setAllMagicQuestions((old) => ({ ...old, status: "loading", items: [] }));
      listAllMagicQuestion(meetingId)
        .then((response) => {
          setAllMagicQuestions((old) => ({
            ...old,
            status: "completed",
            items: response.data.items,
          }));
        })
        .catch(() => {
          setAllMagicQuestions((old) => ({
            ...old,
            status: "error",
            items: [],
          }));
        });
    }
  }, [meetingId]);

  return (
    <>
      {resume.status === "loading" ? (
        <Box>
          <Skeleton animation="wave" variant="rectangular" />
        </Box>
      ) : (
        <PageContainer
          title={`Reunião: ${resume.name || "-"}`}
          style={{ maxWidth: "1280px" }}
          slots={{
            toolbar: () => <PageToolbar date={resume.created_at as string} />,
          }}
          breadcrumbs={[
            { path: "/", title: "Minhas Reuniões" },
            { path: ":meetingId", title: resume.name as string },
          ]}
        >
          {resume.status === "blocked" ? (
            <Box className="blocked-message">
              <p>
                Você atingiu o limite do seu plano FREE. Considere um upgrade a
                partir de R$ 19,90/mês para aumentar o limite de reuniões.
              </p>
              <br />
              <Button
                onClick={() => navigate("/dashboard/configuracoes/cobranca")}
                variant="contained"
                color="primary"
              >
                <ShoppingCart /> ESCOLHER PLANO
              </Button>
            </Box>
          ) : (
            <div className="resume-container">
              <>
                <Card className="rmst-card">
                  <div className="ask-input">
                    <Input
                      initialValue={question}
                      onBlur={(value) => SetQuestion(value)}
                      placeholder="Me peça algo sobre esta reunião"
                    />
                    <button
                      onClick={() => runMagicQuestion()}
                      className="magic-button"
                    >
                      <img src={Magic} alt="" />
                      Faça a mágica
                    </button>
                  </div>
                  <div className="ask-tips">
                    Teste agora:
                    <div
                      onClick={(event) => {
                        const element = event.target as HTMLElement;
                        SetQuestion(element.textContent || "");
                      }}
                    >
                      <span>Resumir esta reunião</span>
                    </div>
                    <div
                      onClick={(event) => {
                        const element = event.target as HTMLElement;
                        SetQuestion(element.textContent || "");
                      }}
                    >
                      <span>Plano de ação no formato 5w2h</span>
                    </div>
                    <div
                      onClick={(event) => {
                        const element = event.target as HTMLElement;
                        SetQuestion(element.textContent || "");
                      }}
                    >
                      <span>
                        Quais são as tarefas de{" "}
                        {resume?.speakers ? resume?.speakers[0]?.name : "NOME"}?{" "}
                      </span>
                    </div>
                    <div
                      onClick={(event) => {
                        const element = event.target as HTMLElement;
                        SetQuestion(element.textContent || "");
                      }}
                    >
                      <span>Crie um e-mail sobre a reunião</span>
                    </div>
                  </div>
                </Card>
                <br />
                <Box
                  display="flex"
                  flexDirection={{ xs: "column", md: "row" }}
                  gap={2}
                  className="participantes-transcricao"
                >
                  <Box display="flex" flexDirection="column">
                    <Card className="rmst-card card-participantes">
                      <h3>Participantes</h3>
                      <ul>
                        {resume.speakers?.map((item) => {
                          return (
                            <li key={item.id} className="rsmt-tab-content">
                              <Avatar
                                alt="Nome do Usuário"
                                src={item.avatar_url}
                                sx={{
                                  width: 28,
                                  height: 28,
                                  marginRight: "4px",
                                }}
                              />
                              <p>
                                <span>{item.name}</span>
                              </p>
                            </li>
                          );
                        })}
                      </ul>
                    </Card>
                    <br />
                    <Card className="rmst-card card-participantes">
                      <h3>Histórico ({resumeHistory.total || "-"})</h3>

                      {resumeHistory.status === "loading" ? (
                        <Box>
                          <Skeleton animation="wave" variant="rectangular" />
                        </Box>
                      ) : (
                        <div>
                          <br />
                          {resumeHistory.items?.map((item) => (
                            <div>
                              <a
                                className="link"
                                href={`#/dashboard/${item.id}`}
                              >
                                {item.code}
                              </a>
                              <span className="legend">{item.created_at}</span>
                              <br />
                              <br />
                            </div>
                          ))}
                          {resumeHistory.total === 0 ? (
                            <span>Não há histórico</span>
                          ) : null}
                        </div>
                      )}
                    </Card>
                  </Box>
                  <Box display="flex" flexDirection="column" gap={2}>
                    {magicQuestion.status !== "idle" ? (
                      <Card className="rmst-card">
                        {magicQuestion.status === "completed" ? (
                          <>
                            <h2>{magicQuestion.question}</h2>
                            <Markdown className="resume-text">
                              {magicQuestion.answer}
                            </Markdown>
                          </>
                        ) : null}
                        {magicQuestion.status === "loading" ? (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img id="loading-img" src={LoadGif} alt="" />
                            <div id="answer-feedback">Carregando...</div>
                          </div>
                        ) : null}
                      </Card>
                    ) : null}

                    {allMagicQuestions.status === "loading" ? (
                      <Loading />
                    ) : (
                      <>
                        {allMagicQuestions.items.length > 0 && (
                          <Typography variant="h6" gutterBottom>
                            Perguntas Mágicas
                          </Typography>
                        )}
                        {allMagicQuestions.items.map((item) => (
                          <Card key={item.questionId} className="rmst-card">
                            <Typography
                              color="#004C8F"
                              variant="h6"
                              gutterBottom
                            >
                              {item.question}
                            </Typography>
                            {
                              item.automation && (
                                <div className="magic-question-header">
                                  <Typography style={{width: "100%", padding: 0}} variant="body1" gutterBottom>Esta pergunta foi feita pela automatização <strong>{item.automation?.name}</strong></Typography>
                                  <Button onClick={() => navigate("/dashboard/configuracoes/automacao")}><AccountTree /> Configurar automatizações</Button>
                                 <br />
                                  <br />
                                </div>
                              )
                            }
                            <div style={{ paddingLeft: "10px", borderLeft: "2px solid #004C8F"}}>
                              <Markdown>{item.answer}</Markdown>
                            </div>
                          </Card>
                        ))}
                      </>
                    )}

                    <Card className="rmst-card">
                      <div className="title-transcription">
                        {" "}
                        <h2>Transcrição</h2>{" "}
                        {/* {meetingId && <MentalMap meetingId={meetingId} />} */}
                      </div>

                      {resumeSpeeches.status === "completed"
                        ? resumeSpeeches?.speeches?.map((item, index) => (
                            <div
                              key={index}
                              className={`row-transcript ${index % 2 === 0 ? "row-gray-transcript" : ""}`}
                            >
                              <img
                                alt=""
                                className="avatar"
                                src={item.avatarUrl}
                              />
                              <div className="col1">
                                <span style={{ fontWeight: 500 }}>
                                  {item.name}
                                </span>
                                <span className="time-message">
                                  {item.spokeAt}
                                </span>
                              </div>
                              <div className="col2">{item.content}</div>
                            </div>
                          ))
                        : null}
                    </Card>
                  </Box>
                </Box>
              </>
            </div>
          )}
        </PageContainer>
      )}
    </>
  );
};

export default ResumePage;
