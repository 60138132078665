import { Box, CircularProgress, Typography } from "@mui/material";
import { Logo } from "static/images";
import React, { useEffect } from "react";
import { PageContainer } from "@toolpad/core";
import { getInfoUser } from "utils/services";
import { useNavigate } from "react-router-dom";
// import { useGlobalState } from "components/GlobalProvider";

interface ExtensionSyncProps {
  onFinish?: (success: boolean) => void;
}

export const ExtensionSync: React.FC<ExtensionSyncProps> = ({ onFinish }) => {
  const accessToken = localStorage.getItem("access_token");
  const navigate = useNavigate();

  const syncUser = async () => {
    if (!accessToken) return;
    try {
      await getInfoUser();
      navigate("/dashboard");
      if (onFinish) onFinish(true);
    } catch (error) {
      console.error("Erro ao sincronizar usuário:", error);
      if (onFinish) onFinish(true);
    }
  };

  const checkUrl = async () => {
    const params = new URLSearchParams(window.location.hash.slice(2));
    const urlToken = params.get("token");
    if (urlToken) {
      localStorage.setItem("access_token", urlToken);
      await syncUser();
      return;
    }
  }

  const handleToken = async (token: string) => {
    if (token) {
      localStorage.setItem("access_token", token);
      await syncUser();
      return;
    } else {
      checkUrl()
    }
    if (onFinish) onFinish(true);
    return;
  };

  useEffect(() => {

    if(accessToken) {
      syncUser()
    }
    
    window.resumeAuth = handleToken;

    setTimeout(() => {
      if(!accessToken) {
        (window as any).resumeAuth()
      }
    }, 3000)
  }, []);

  return <PageContainer sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
    <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', margin: '32px 0' }} >
      <img height="33px" width="auto" src={Logo} alt='Logo Resume Tudo' style={{ marginBottom: 32 }} />
      <CircularProgress />
      <Typography variant="body2" color="text.secondary" style={{ margin: '18px 0 0' }}>Carregando seus dados</Typography>
    </Box>
  </PageContainer>
    ;
};
