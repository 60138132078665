import React, { useEffect, useState } from "react";
import { createTheme } from "@mui/material/styles";
import {
  AppProvider,
  Branding,
  type Navigation,
} from "@toolpad/core/AppProvider";
import { Logo } from "static/images";
import { Tag, PaymentRounded, AccountTree } from "@mui/icons-material";
import { Outlet } from "react-router-dom";
import { Alert, Snackbar } from "@mui/material";
import InfoUserModal from "components/InfoUserModal";
import { useGlobalState } from "components/GlobalProvider";
import { saveUserInfos } from "utils/services";

const NAVIGATION: Navigation = [
  {
    segment: "#/dashboard",
    title: "Reuniões",
    icon: <Tag />,
    kind: "page",
  },

  {
    segment: "#/dashboard/configuracoes/cobranca",
    title: "Cobrança",
    icon: <PaymentRounded />,
  },
  {
    segment: "#/dashboard/configuracoes/automacao",
    title: "Automações",
    icon: <AccountTree />,
  },
  {
    kind: "divider",
  },
];

const BRANDING: Branding = {
  logo: <img height="33px" width="auto" src={Logo} alt="ResumeTudo" />,
  title: "",
};

const themeResumeTudo = createTheme({
  cssVariables: {
    colorSchemeSelector: "data-toolpad-color-scheme",
  },
  colorSchemes: { light: true, dark: false },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default function DashboardResumeTudo() {
  const { user } = useGlobalState();
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [openSnack, setOpenSnack] = useState(false)

  
  useEffect(() => {
    if(user && user?.cadastral_info === undefined) {
      setOpen(true)
    }
  }, [user])

  return (
       <AppProvider
      navigation={NAVIGATION}
      theme={themeResumeTudo}
      branding={BRANDING}
      defaultDrawerOpen={false}
    >
      <Outlet />
      <InfoUserModal
        open={open}
        isLoading={isLoading}
        data={user}
        handleSave={(value: any) => {
          setIsLoading(true)

          saveUserInfos({
            phone: value.phone,
            profession: value.profession,
            work_description: value.work_description
          }).then(() => {
            setOpen(false)
            setOpenSnack(openSnack)
            setIsLoading(false)
          }).catch((err) => {
            console.error(err)
            setIsLoading(false)
            setOpen(false)
          })

        }}
        handleClose={() => { }}
      />
      <Snackbar
        open={openSnack}
        autoHideDuration={4000}
        onClose={() => { setOpenSnack(false) }}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          Informações salvas com sucesso!
        </Alert>
      </Snackbar>
    </AppProvider>
  );
}
