import React, { FC, useState } from "react"
import { PageContainer } from "@toolpad/core"
import { Box, Button, Card, CardContent, Chip, CircularProgress, Modal, Stack, Typography, Alert } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { useGlobalState } from "components/GlobalProvider";
import { getPaymentLink } from "utils/services";
import './styles.scss';
import { Subscription } from "utils/types";

interface PlanProps {
    planName: string
    type: 'free' | 'basic' | 'pro' | 'enterprise'
    isRecommended?: boolean
    isSelected?: boolean
    isContracted?: boolean
    countTransc?: number
    countAsk?: number
    limitTransc?: number
    limitAsk?: number
    subscription?: Subscription
}

function PlanCard(props: PlanProps) {
    // const progressTransc = (props.countTransc && props.limitTransc) ? props.countTransc / props.limitTransc * 100 : 0;
    // const progressAsk = (props.countAsk && props.limitAsk) ? props.countAsk / props.limitAsk * 100 : 0;
    const [open, setOpen] = useState(false)
    const generateLink = (planId: string) => {
        setOpen(true)
        getPaymentLink(planId).then((response) => {
            setOpen(false)
            window.open(response.data.payment_link, '_blank')
        })
    }
    return <Card className={`plan-card ${props.isRecommended && 'recommended'} ${props.isContracted && 'contracted'}`} sx={{ width: '100%' }}>
        <CardContent>
            {props.isRecommended && !props.isContracted && <Chip label='Recomendado' color="primary" />}
            {props.isContracted && <Chip label='Contratado' color="success" />}
            <Typography gutterBottom sx={{ fontSize: 28, fontWeight: '500' }}>
                {props.planName}
            </Typography>

            {props.type === 'free' &&
                <Stack className="free">
                    <Typography sx={{ fontSize: 48, fontWeight: '500' }}>R$0,00</Typography>
                    <Typography gutterBottom sx={{ fontSize: 16, fontWeight: '400', color: 'text.secondary' }}>Para começar. Sempre Grátis. É só instalar e usar.</Typography>
                    <Button variant="contained" href="https://chromewebstore.google.com/detail/resume-tudo-transcri%C3%A7%C3%A3o-r/ggbmbbmfgchafopbbmmdgklehkpaoell" target="_blank">Instalar Grátis</Button>
                    <Typography gutterBottom sx={{ fontSize: 16, fontWeight: '500' }}></Typography>
                    <Typography gutterBottom display={'flex'}><Check style={{color: "green"}} sx={{ marginRight: 1 }} />Transcrições ilimitadas</Typography>
                <Typography gutterBottom display={'flex'}><Check style={{color: "green"}} sx={{ marginRight: 1 }} />Visualize 0 reunião /mês</Typography>
                <Typography gutterBottom display={'flex'}><Check style={{color: "green"}} sx={{ marginRight: 1 }} />2 Perguntas IA /mês</Typography>
                <Typography gutterBottom display={'flex'}><Close style={{color: "red"}} sx={{ marginRight: 1 }} />Modo anônimo</Typography>
                <Typography gutterBottom display={'flex'}><Close style={{color: "red"}} sx={{ marginRight: 1 }} />Baixar transcrição</Typography>
                <Typography gutterBottom display={'flex'}><Close style={{color: "red"}} sx={{ marginRight: 1 }} />Automações</Typography> 
                </Stack>}

            {props.type === 'basic' && <Stack className="basic">
                <Typography sx={{ fontSize: 48, fontWeight: '500' }}>R$19,90</Typography>
                <Typography gutterBottom sx={{ fontSize: 16, fontWeight: '400', color: 'text.secondary' }}>Por usuário, por mês, cobrado mensalmente</Typography>
                {!props.isContracted && <Button variant="contained" onClick={() => generateLink('basic')}>Contratar agora</Button>}

                <Typography gutterBottom display={'flex'}><Check style={{color: "green"}} sx={{ marginRight: 1 }} />Transcrições ilimitadas</Typography>
                <Typography gutterBottom display={'flex'}><Check style={{color: "green"}} sx={{ marginRight: 1 }} />Visualize 10 reuniões /mês</Typography>
                <Typography gutterBottom display={'flex'}><Check style={{color: "green"}} sx={{ marginRight: 1 }} />20 Perguntas IA /mês</Typography>
                <Typography gutterBottom display={'flex'}><Check style={{color: "green"}} sx={{ marginRight: 1 }} />Modo anônimo</Typography>
                <Typography gutterBottom display={'flex'}><Check style={{color: "green"}} sx={{ marginRight: 1 }} />Baixar transcrição</Typography>
                <Typography gutterBottom display={'flex'}><Close style={{color: "red"}} sx={{ marginRight: 1 }} />Automações</Typography>                     
            </Stack>}

            {props.type === 'pro' && <Stack className="pro">
                <Typography sx={{ fontSize: 48, fontWeight: '500' }}>R$29,90</Typography>
                <Typography gutterBottom sx={{ fontSize: 16, fontWeight: '400', color: 'text.secondary' }}>Por usuário, por mês, cobrado mensalmente</Typography>
                {!props.isContracted && <Button variant="contained" onClick={() => generateLink('pro')}>Contratar agora</Button>}
                {props.isContracted && <Alert className="alert-plan-active" severity="success">
                    O seu plano está ativo
                </Alert>}
                <Typography gutterBottom display={'flex'}><Check style={{color: "green"}} sx={{ marginRight: 1 }} />Transcrições ilimitadas</Typography>
                <Typography gutterBottom display={'flex'}><Check style={{color: "green"}} sx={{ marginRight: 1 }} />Visualize reuniões ilimitadas</Typography>
                <Typography gutterBottom display={'flex'}><Check style={{color: "green"}} sx={{ marginRight: 1 }} />Perguntas IA ilimitadas</Typography>
                <Typography gutterBottom display={'flex'}><Check style={{color: "green"}} sx={{ marginRight: 1 }} />Modo anônimo</Typography>
                <Typography gutterBottom display={'flex'}><Check style={{color: "green"}} sx={{ marginRight: 1 }} />Baixar transcrição</Typography>
                <Typography gutterBottom display={'flex'}><Check style={{color: "green"}} sx={{ marginRight: 1 }} />Até 3 Automações</Typography>                
            </Stack>}

            {props.type === 'enterprise' && <Stack className="enterprise">
                <Typography sx={{ fontSize: 48, fontWeight: '500' }}>Custom</Typography>
                <Typography gutterBottom sx={{ fontSize: 16, fontWeight: '400', color: 'text.secondary' }}>Condições especiais para empresas com múltiplos usuários.</Typography>
                {!props.isContracted && <Button variant="contained" onClick={() => {
                    window.open('https://wa.me/5511965690339', '_blank');
                }}>Falar com especialista</Button>}
                <Typography gutterBottom display={'flex'}><Check style={{color: "green"}} sx={{ marginRight: 1 }} />Transcrições ilimitadas</Typography>
                <Typography gutterBottom display={'flex'}><Check style={{color: "green"}} sx={{ marginRight: 1 }} />Visualize reuniões ilimitadas</Typography>
                <Typography gutterBottom display={'flex'}><Check style={{color: "green"}} sx={{ marginRight: 1 }} />Perguntas IA ilimitadas</Typography>
                <Typography gutterBottom display={'flex'}><Check style={{color: "green"}} sx={{ marginRight: 1 }} />Integrações via API</Typography>
                <Typography gutterBottom display={'flex'}><Check style={{color: "green"}} sx={{ marginRight: 1 }} />Modo anônimo</Typography>
                <Typography gutterBottom display={'flex'}><Check style={{color: "green"}} sx={{ marginRight: 1 }} />Baixar transcrição</Typography>  
                <Typography gutterBottom display={'flex'}><Check style={{color: "green"}} sx={{ marginRight: 1 }} />Mais que 3 Automações</Typography>  
            </Stack>}
            <LinkGenModal open={open} handleClose={() => { setOpen(false) }} />
        </CardContent>
    </Card>
}
interface LinkGenModalProps {
    open: boolean;
    handleClose: Function;
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    textAlign: 'center'
};

const LinkGenModal: FC<LinkGenModalProps> = (props: LinkGenModalProps) => {
    return <Modal
        open={props.open}
        onClose={() => props.handleClose()}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
    >
        <Box sx={{ ...style, width: 400 }}>
            <Typography sx={{ fontSize: 32, fontWeight: '400', marginBottom: 4 }}>Aguarde, gerando link de pagamento</Typography>
            <CircularProgress />
        </Box>
    </Modal>
}



const Cobranca: FC = () => {
    const { user } = useGlobalState();

    return <PageContainer
        title="Cobrança"
        breadcrumbs={[]}
        style={{ maxWidth: '1280px' }}>
            {user?.subscription.is_trial && <Alert sx={{marginBottom: '16px'}}>VOCÊ ESTÁ EM UM PLANO PROMOCIONAL POR 7 DIAS</Alert>}
        <Stack direction="row" spacing={2} >
            {/* <PlanCard planName={'Free'} type={'free'} isContracted={user?.subscription.plan_id === 'free'} /> */}
            <PlanCard planName={'Basic'} type={'basic'} isContracted={user?.subscription.plan_id === 'basic' && !user?.subscription.is_trial} />
            <PlanCard planName={'Pro'} type={'pro'} isRecommended={user?.subscription.plan_id !== 'pro' && !user?.subscription.is_trial} isContracted={user?.subscription.plan_id === 'pro' && !user?.subscription.is_trial} />
            <PlanCard planName={'Enterprise'} type={'enterprise'} isContracted={user?.subscription.plan_id === 'enterprise'} />
        </Stack>
    </PageContainer>
}

export default Cobranca;