import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";

const jobOptions = [
  // "Franquias": 
  "Diretor de Expansão de Franquias",
  "Gerente de Expansão de Franquias",
  "Consultor de Expansão",
  "Consultor de Franquias",
  "Coordenador de Operações de Franquias",
  "Supervisor de Campo (Field Consultant)",
  "Analista de Suporte ao Franqueado",
  "Treinador de Franqueados",
  "Gerente de Relacionamento com Franqueados",
  "Especialista em Padronização e Processos",
  // "Tecnologia": [
  "Desenvolvedor de Software",
  "Arquiteto de Software",
  "CTO (Chief Technology Officer)",
  "Cientista de Dados",
  "Analista de Dados",
  "Engenheiro de Dados",
  "DevOps",
  "Analista de Segurança da Informação",
  "UX/UI Designer",
  "Consultor de TI",
  // "Produtos e Metodologias Ágeis": [
  "Product Manager",
  "Product Owner",
  "Head de Produtos",
  "Analista de Produto",
  "UX Researcher",
  "Agilista",
  "Scrum Master",
  // "Psicologia": [
  "Psicólogo Clínico",
  "Psicólogo Organizacional",
  "Psicólogo Escolar",
  "Neuropsicólogo",
  "Psicoterapeuta",
  "Psicólogo do Esporte",
  // "Comercial": [
  "Diretor Comercial",
  "Gerente Comercial",
  "Consultor Comercial",
  "Closer de Vendas",
  "Executivo de Vendas",
  "Representante de Vendas",
  "SDR (Sales Development Representative)",
  "Vendedor",
  // "Marketing": [
  "Diretor de Marketing (CMO)",
  "Gerente de Marketing",
  "Coordenador de Marketing",
  "Analista de Marketing",
  "Analista de Marketing Digital",
  "Especialista em SEO",
  "Especialista em Tráfego Pago",
  "Social Media Manager",
  "Copywriter",
  "Growth Marketing Manager",
  "Estrategista de Conteúdo",
  "Designer Gráfico",
  "Videomaker",
  "Especialista em Branding",
  // "Recursos Humanos (RH)": [
  "Diretor de Recursos Humanos",
  "Gerente de Recursos Humanos",
  "Business Partner de RH",
  "Recrutador",
  "Analista de Recrutamento e Seleção",
  "Analista de Departamento Pessoal",
  "Coordenador de Treinamento e Desenvolvimento",
  "Analista de Treinamento e Desenvolvimento",
  "Especialista em Treinamento Corporativo",
  "Gerente de Treinamento e Desenvolvimento",
  "Instrutor Corporativo",
  "Facilitador de Treinamentos",
  "Especialista em Educação Corporativa",
  "Consultor de Treinamento",
  "Treinador de Líderes",
  "Coordenador de Programas de Capacitação",
  "Analista de Desenvolvimento de Talentos",
  "Coach Corporativo",
  // "Customer Success (CS)": [
  "Diretor de Customer Success",
  "Gerente de Customer Success",
  "Customer Success Manager (CSM)",
  "Analista de Sucesso do Cliente",
  "Especialista em Onboarding",
  // "Logística": [
  "Diretor de Logística",
  "Gerente de Logística",
  "Coordenador de Logística",
  "Supervisor de Logística",
  "Analista de Logística",
  "Especialista em Supply Chain",
  "Planejador de Demanda",
  "Gestor de Frota",
  "Supervisor de Armazém",
  "Estoquista",
  "Analista de Transporte",
  "Conferente",
  // "Arquitetura": [
  "Arquiteto(a)",
  "Arquiteto de Edificações",
  "Arquiteto de Interiores",
  "Arquiteto Urbanista",
  "Engenheiro Civil (trabalhando com arquitetura)",
  "Paisagista",
  // "Advocacia": [
  "Advogado(a)",
  "Advogado Trabalhista",
  "Advogado Empresarial",
  "Advogado Civil",
  "Advogado Penal",
  "Advogado Tributário",
  "Advogado Contratualista",
  "Compliance Officer",
  // "Riscos e Compliance": [
  "Diretor de Compliance (CCO)",
  "Gerente de Compliance",
  "Coordenador de Compliance",
  "Analista de Compliance",
  "Auditor Interno",
  "Analista de Riscos",
  "Gerente de Riscos e Controles Internos",
  "Analista de Prevenção à Fraude",
  "Especialista em Prevenção à Lavagem de Dinheiro (PLD)",
  "Consultor de Compliance e Governança",
  "Especialista em Controles Internos",
  "Oficial de Conformidade Regulatória",
  // "Pesquisa e Inteligência de Mercado": [
  "Diretor de Inteligência de Mercado",
  "Gerente de Inteligência de Mercado",
  "Coordenador de Pesquisa de Mercado",
  "Analista de Pesquisa de Mercado",
  "Especialista em Inteligência Competitiva",
  "Analista de Inteligência de Mercado",
  "Pesquisador de Mercado",
  "Especialista em Big Data e Inteligência de Mercado",
  "Consultor de Inteligência de Mercado",
  "Data Analyst",
  "Cientista de Dados de Mercado",
  "Analista de Tendências de Mercado",
  "Coordenador de Análise de Concorrência",
  "Especialista em Análise de Dados",
  // "Ouvidoria": [
  "Ouvidor(a)",
  "Coordenador de Ouvidoria",
  "Analista de Ouvidoria",
  "Assistente de Ouvidoria",
  "Consultor de Ouvidoria",
  "Especialista em Gestão de Ouvidoria",
  "Analista de Relacionamento com o Cliente",
  "Supervisor de Ouvidoria",
  "Ouvidor(a) Corporativo(a)",
];

interface JobAutocompleteProps {
  profession: string | undefined;
  onSelect: (value: string | null) => void; // Aceita uma string ou null
}

const JobAutocomplete: React.FC<JobAutocompleteProps> = ({ profession, onSelect }) => {
  
  const [update, setUpdate] = useState('')
  useEffect(()=> {
    if(profession !== undefined) {
      setUpdate(profession)
    }
  }, [profession])
  
  return (
    <Autocomplete
      onChange={(event, value) => { onSelect(value) }}
      options={jobOptions}
      value={update} 
      renderInput={(params) => (
        <TextField 
          {...params}
          label="Profissão"
          name="profession"
          variant="outlined" required />
      )}
      fullWidth
    />
  );
};

export default JobAutocomplete;
