import React from 'react';
import { Box, Typography, Chip } from '@mui/material';

interface AutomationDisplayProps {
  automation: {
    name: string;
    trigger_type: string;
    public_id: string;
    action_params: Record<string, any>;
    created_at: string;
    action_type: string;
    trigger_conditions: Record<string, any>;
  };
}

const AutomationDisplay: React.FC<AutomationDisplayProps> = ({ automation }) => {
  const getActionTypeLabel = (actionType: string) => {
    switch (actionType) {
      case 'send_magic_question':
        return 'Enviar pergunta mágica';
      case 'create_magic_question':
        return 'Criar pergunta mágica';
      case 'send_transcription':
        return 'Enviar transcrição';
      default:
        return actionType;
    }
  };

  const getConditionLabel = (condition: string, value: any, operation: string) => {
    switch (condition) {
      case 'day_of_the_week':
        const days = ['Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado', 'Domingo'];
        return `A reunião ${getOperationLabel(operation)} ${days[value]}`;
      case 'meeting_duration':
        return `A duração da reunião ${getOperationLabel(operation)} ${value} minutos`;
      case 'meeting_name_pattern':
        return `O nome da reunião ${getOperationLabel(operation)} "${value}"`;
      case 'participant_name_pattern':
        return `O nome do participante ${getOperationLabel(operation)} "${value}"`;
      case 'words_spoken':
        return `As palavras "${value.join(', ')}" foram faladas`;
      case 'always':
        return 'Todas as reuniões';
      case 'meeting_participants_count':
        return `O número de participantes da reunião ${getOperationLabel(operation)} ${value}`;
      default:
        return `${condition}: ${operation} ${value}`;
    }
  };

  const getOperationLabel = (operation: string) => {
    switch (operation) {
      case 'startswith':
        return 'começa com';
      case 'endswith':
        return 'termina com';
      case 'in':
        return 'contém';
      case 'not_in':
        return 'não contém';
      case '==':
        return 'é igual a';
      case '!=':
        return 'não é';
      default:
        return operation;
    }
  };

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle1">Ação:</Typography>
        <Chip label={getActionTypeLabel(automation.action_type)} sx={{ m: 0.5 }} />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle1">Condições:</Typography>
        {Object.entries(automation.trigger_conditions).map(([key, condition]) => (
          <Chip
            key={key}
            label={getConditionLabel(key, condition.value, condition.operation)}
            sx={{ m: 0.5 }}
          />
        ))}
      </Box>
    </>
  );
};

export default AutomationDisplay;