import React, { FC, useState, useEffect } from "react";
import { PageContainer } from "@toolpad/core";
import {
  Box,
  Typography,
  Card,
  Button,
  Modal,
  TextField,
  Alert,
} from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Add, ArrowBack, ArrowDownward, Delete } from "@mui/icons-material";
import {
  createAutomation,
  getAutomationOptions,
  listAutomations,
  deleteAutomation,
} from "utils/services";
import { AutomationSelectComponent } from "./RenderComponentByParams";
import Loading from "components/Loading";
import AutomationDisplay from "./ResumeAutomation";
import { useGlobalState } from "components/GlobalProvider";

interface Automation {
  public_id: string;
  name: string;
  trigger_type: string;
  action_type: string;
  is_active: boolean;
  created_at: string;
}

export interface AutomationOptions {
  meeting_end: {
    actions: Action[];
    conditions: Condition[];
  };
}

interface Action {
  label: string;
  value: ActionType;
  params: Param[];
}

interface Param {
  label: string;
  param_name: string;
  input_type: string;
  value_type?: string;
  options?: Option[];
}

interface Condition {
  value: string;
  label: string;
  value_type: string;
  input_type?: string;
  layout?: string;
  params?: Param[];
}

interface Option {
  label: string;
  value: string | number;
}

enum ActionType {
  CREATE_MAGIC_QUESTION = "CREATE_MAGIC_QUESTION",
  SEND_MAGIC_QUESTION = "SEND_MAGIC_QUESTION",
  SEND_TRANSCRIPTION = "SEND_TRANSCRIPTION",
}

interface AutomationListingState {
  status: "idle" | "loading" | "completed" | "error";
  items: Automation[];
}

const Cobranca: FC = () => {
  const [automations, setAutomations] = useState<AutomationListingState>({
    status: "idle",
    items: [],
  });
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [actionOptions, setActionOptions] = useState<AutomationOptions>();
  const [isLoading, setIsLoading] = useState(false);
  const [automationConfig, setAutomationsConfig] = useState<
    Record<string, any>
  >({});
  const [name, setName] = useState("");
  const [triggerConditions, setTriggerConditions] = useState<
    Record<string, any>
  >({});

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [automationToDelete, setAutomationToDelete] =
    useState<Automation | null>(null);

  const [expanded, setExpanded] = useState(false);
  const { user } = useGlobalState();

  const allAutomations = () => {
    setAutomations((prevState) => ({ ...prevState, status: "loading" }));
    listAutomations()
      .then((response) => {
        setAutomations((prevState) => ({
          ...prevState,
          status: "completed",
          items: response.data.items,
        }));
      })
      .catch(() =>
        setAutomations((prevState) => ({
          ...prevState,
          status: "error",
          items: [],
        }))
      );
  };
  console.log("USER: ", user);

  useEffect(() => {
    allAutomations();
  }, []);

  useEffect(() => {
    getAutomationOptions().then((response) => {
      setActionOptions(response.data);
    });
  }, []);

  const onCreateAutomation = () => {
    setIsLoading(false);
    createAutomation({
      name,
      trigger_type: "meeting_end",
      action_type: automationConfig.action_type,
      trigger_conditions: triggerConditions,
      action_params: automationConfig.action_params,
    })
      .then(() => {
        setIsCreateModalOpen(false);
        allAutomations();
      })
      .catch(() => console.log("ERROR"))
      .finally(() => setIsLoading(false));
  };

  const handleDeleteClick = (automation: Automation) => {
    setAutomationToDelete(automation);
    setDeleteConfirmOpen(true);
  };

  useEffect(() => {
    if (!isCreateModalOpen) {
      // setActionOptions();
      setTriggerConditions({});
      setAutomationsConfig({});
      setName("");
    }
  }, [isCreateModalOpen]);

  const handleDeleteConfirm = () => {
    if (automationToDelete) {
      deleteAutomation(automationToDelete.public_id)
        .then(() => {
          allAutomations(); // Refresh the list after deletion
          setDeleteConfirmOpen(false);
          setAutomationToDelete(null);
        })
        .catch((error) => {
          console.error("Error deleting automation:", error);
          // You might want to show an error message to the user here
        });
    }
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
    setAutomationToDelete(null);
  };

  return (
    <PageContainer
      title={`Automações (${automations.items.length} / 3)`}
      breadcrumbs={[]}
      style={{ maxWidth: "1280px" }}
    >
      {
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsCreateModalOpen(true)}
          disabled={
            user?.subscription.plan_id !== "pro" && automations.items.length < 3
          }
        >
          <Add /> Criar Automação
        </Button>
      }
      {user?.subscription.plan_id !== "pro" && (
        <>
          <br />
          <br />
          <Alert severity="warning" sx={{ width: "100%" }}>
            <span>
              Para utilizar as automações, você precisar de uma assinatura Pro.
            </span>
          </Alert>
        </>
      )}
      <br />
      {automations.status === "loading" ? (
        <Loading size={20} />
      ) : (
        automations.items.map((automation, index) => (
          <Card key={index} sx={{ margin: 2 }}>
            <div className="automation-list-item">
              <div>
                <Typography variant="h6" gutterBottom>
                  {automation.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Criado em: {automation.created_at}
                </Typography>

                <Box>
                  <Button onClick={() => setExpanded(!expanded)}>
                    {expanded ? "Ocultar detalhes" : "Mostrar detalhes"}
                  </Button>
                </Box>
              </div>

              <AutomationDisplay automation={automation as any} />

              <Button
                color="error"
                disabled={user?.subscription.plan_id !== "pro"}
                onClick={() => handleDeleteClick(automation)}
              >
                <Delete />
              
              </Button>
    
            </div>
            {expanded && (
              <div className="automation-item-params">
                {Object.entries(
                  (automation as any).action_params as Record<string, string>
                ).map(([key, value_item]) => (
                  <>
                    <Typography variant="subtitle1" key={key}>
                      {key.toUpperCase()}:
                    </Typography>
                    <Typography variant="body2" key={`${key}_value`}>
                      {value_item}
                    </Typography>
                  </>
                ))}
              </div>
            )}
          </Card>
        ))
      )}

      {isCreateModalOpen && (
        <Modal
          open={isCreateModalOpen}
          onClose={() => setIsCreateModalOpen(false)}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              height: "80%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              overflowY: "scroll",
            }}
          >
            <Button onClick={() => setIsCreateModalOpen(false)}>
              <ArrowBack />
            </Button>
            <br />
            <br />
            <div className="automation-container">
              <Typography variant="h6" gutterBottom>
                Criar Automação
              </Typography>

              <TextField
                style={{ width: "100%" }}
                label="Nome da Automação"
                placeholder="Nome da Automação"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <br />
              <br />
              <AutomationSelectComponent
                prefix="Fazer: "
                onUpdateParams={(automation, data) => {
                  if (typeof data === "object") {
                    setAutomationsConfig((old) => ({
                      ...old,
                      [automation]: {
                        ...old[automation],
                        ...data,
                      },
                    }));
                  } else {
                    setAutomationsConfig((old) => ({
                      ...old,
                      [automation]: data,
                    }));
                  }
                }}
                onUpdateActionType={(data) =>
                  setAutomationsConfig((old) => ({ ...old, ...data }))
                }
                onResetParams={() => setAutomationsConfig({})}
                options={actionOptions?.meeting_end.actions || []}
                placeholder="Fazer ..."
              />

              <ArrowDownward style={{ color: "rgb(121, 126, 147)" }} />

              {/* condições */}
              <AutomationSelectComponent
                prefix=""
                onUpdateParams={(automation, data) => {
                  if (typeof data === "object") {
                    setTriggerConditions((old) => ({
                      ...old,
                      [automation]: {
                        ...old[automation],
                        ...data,
                      },
                    }));
                  } else {
                    setTriggerConditions((old) => ({
                      ...old,
                      [automation]: data,
                    }));
                  }
                }}
                onResetParams={() => setTriggerConditions({})}
                options={actionOptions?.meeting_end.conditions || []}
                placeholder="Se ..."
              />

              <br />

              <Button onClick={onCreateAutomation} variant="contained">
                {isLoading ? <Loading /> : <Add />} Criar automação
              </Button>
            </div>
          </Box>
        </Modal>
      )}
      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Você tem certeza que deseja excluir a automação "
            {automationToDelete?.name}"? Esta ação não pode ser desfeita.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteConfirm} color="error" autoFocus>
            Excluir
          </Button>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </PageContainer>
  );
};

export default Cobranca;
