import React, { useEffect, useState, useRef, ChangeEvent } from "react";
import "./styles.scss";

interface InputProps {
    onBlur: (value: string) => void,
    initialValue: string
    placeholder?: string,
}

export const Input = ({ onBlur, initialValue, placeholder }: InputProps) => {
    const [value, setValue] = useState("")
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    useEffect(() => {
        adjustHeight();
    }, [value]);

    const adjustHeight = () => {
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    };

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setValue(event.target.value);
    };
    return (
        <textarea
            ref={textareaRef}
            value={value}
            onChange={handleChange}
            className="resume-input"
            onBlur={(event) => onBlur(event.target.value)}
            placeholder={placeholder}
            rows={3}
        />
    )
}