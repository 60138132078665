import * as React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import { Box, Fab, Link } from '@mui/material';
import { useSessionStorageState } from '@toolpad/core';
import { googleLogout } from '@react-oauth/google';
import { UserProfile } from 'utils/types';
import { useGlobalState } from 'components/GlobalProvider';
import { Logout, WhatsApp } from '@mui/icons-material';

export default function Layout() {
  const { user } = useGlobalState();

  return (

    <DashboardLayout defaultSidebarCollapsed slotProps={
      {
        toolbarAccount: {
          localeText: { iconButtonAriaLabel: 'test', signInLabel: 'Logar', signOutLabel: 'Sair' },
          slotProps: { signInButton: <></>, signOutButton: <>Sair</> }
        },
        toolbarActions: <>toolbarActions</>
      }
    }
      slots={{
        sidebarFooter: () => <AccountOptions imgUrl="/static/images/avatar/1.jpg" user={user} />

      }}
    >
      <Outlet />
      <Fab size="medium" style={{ color: "#fff", background: '#25d366', position: 'absolute', right: '24px', bottom: '24px' }} aria-label="add" target='_blank' href='https://api.whatsapp.com/send/?phone=5511965690339&text=Ol%C3%A1+%2AResume+Tudo%2A%21+Eu+preciso+de+ajuda+com+um+problema%21&type=phone_number&app_absent=0'>
        <WhatsApp style={{fontSize: '34px'}}/>
      </Fab>
    </DashboardLayout>
  );
}

interface AccountOptionsProps {
  user: UserProfile | undefined;
  imgUrl: string
}

export const AccountOptions = (props: AccountOptionsProps) => {
  const [, setAccessToken] = useSessionStorageState('access_token', null)
  const navigate = useNavigate();

  const logout = () => {
    googleLogout()
    setAccessToken(null)
    localStorage.clear();
    navigate("/login");
  };


  return (
    <Box sx={{ width: '100%', display: "flex", justifyContent: "center" }}>
      <Link style={{cursor: "pointer", fontSize: "16px"}} onClick={logout}>
      <Logout />
      </Link>
    </Box >
  );
}