import React, { useEffect } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { PageContainer } from "@toolpad/core";
import GoogleLoginButton from "./GoogleLink";
import { Logo } from "static/images";
import MagicLink from "./MagicLink";
import { useNavigate } from "react-router-dom";

export default function OAuthSignInPage() {
  const params = new URLSearchParams(window.location.hash.split("?")[1]);
  const urlToken = params.get("token");
  const navigate = useNavigate();

  useEffect(() => {
    console.log("urlToken:", urlToken);
    if (urlToken) {
      localStorage.setItem("access_token", urlToken);
      navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      navigate("/dashboard");
    }
  }, [])

  return (
    <PageContainer
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          margin: "32px 0",
        }}
      >
        <img height="33px" width="auto" src={Logo} alt="Logo Resume Tudo" />
        <Typography
          variant="body2"
          color="text.secondary"
          style={{ margin: "18px 0 0" }}
        >
          Entre para obter os resumos e transcrições das reuniões
        </Typography>
      </Box>
      <GoogleLoginButton />
      <Divider sx={{ margin: "24px 0" }}>
        {" "}
        <Typography variant="body2" color="text.secondary">
          Ou receba um link mágico
        </Typography>
      </Divider>
      <MagicLink />
    </PageContainer>
  );
}
