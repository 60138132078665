import React, { useState } from "react";
import "./styles.scss";
import { Box, MenuItem, Select, TextField } from "@mui/material";

interface AutomationSelectComponentProps {
  placeholder: string;
  prefix: string;
  options?: any[];
  onResetParams?: () => void;
  onUpdateActionType?: (data: Record<string, string>) => void;
  onUpdateParams: (
    automation: string,
    data: Record<string, string | number> | boolean
  ) => void;
}

interface renderComponentByParamsProps {
  param: Record<string, any>;
  automation: string;
  onChange: (automation: string, data: Record<string, string | number> | boolean) => void;
}

const RenderComponentByParams: React.FC<renderComponentByParamsProps> = ({
  param,
  automation,
  onChange,
}) => {
  const wrapChange = (data: string | number) => {
    let changedData: Record<string, string | number> = {};
    changedData[param.param_name] = data;
    onChange(automation, changedData);
  };

  switch (param.input_type) {
    case "text":
      return (
        <TextField
          onBlur={(event) => wrapChange(event.target.value)}
          required
          label={param.label}
          fullWidth
        />
      );

    case "number":
      return (
        <TextField
          onBlur={(event) => wrapChange(event.target.value)}
          required
          type="number"
          label={param.label}
          fullWidth
        />
      );

    case "select":
      return (
        <React.Fragment>
          <label>{param.label}</label>
          <Select
            onBlur={(event) => wrapChange(event.target.value)}
            required
            fullWidth
          >
            {param?.options.map((option: any) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </React.Fragment>
      );
    default:
      return (
        <TextField
          onBlur={(event) => wrapChange(event.target.value)}
          multiline
          rows={3}
          required
          label={param.label}
          fullWidth
        />
      );
  }
};

export const AutomationSelectComponent: React.FC<
  AutomationSelectComponentProps
> = ({
  placeholder,
  options,
  prefix,
  onUpdateParams,
  onResetParams,
  onUpdateActionType,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [hoveredOption, setHoveredOption] = useState<string>();
  const [option, setOption] = useState<Record<string, any>>();

  return (
    <div className="automation-select-component">
      <div
        onClick={() => setShowOptions(!showOptions)}
        className={
          hoveredOption ? "option-selected" : "automation-select-placeholder"
        }
      >
        {hoveredOption ? `${prefix}${hoveredOption}` : placeholder}
      </div>
      {showOptions && (
        <Box className="automation-select-box-options">
          {options &&
            options.map((option) => (
              <div
                className="automation-select-option"
                onMouseLeave={() => setHoveredOption(undefined)}
                onMouseOver={() => setHoveredOption(option.label)}
                onClick={() => {
                  onResetParams && onResetParams();
                  onUpdateActionType &&
                    onUpdateActionType({ "action_type": option.action_type });
                  setOption(option);
                  setShowOptions(false);
                  if (option.default_value) {
                    onUpdateParams(option.value, option.default_value)
                  }
                }}
                key={option.value}
              >
                {prefix}
                {prefix ? option.label.toLowerCase() : option.label}
              </div>
            ))}
        </Box>
      )}
      <br />
      {option?.params && (
        <Box className="automation-option-params ">
          {option.params.map((param: any) => (
            <RenderComponentByParams
              param={param}
              automation={option.value}
              onChange={onUpdateParams}
            />
          ))}
        </Box>
      )}
    </div>
  );
};

export default AutomationSelectComponent;
