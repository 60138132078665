import React from "react";

interface ProtectedRouteProps {
  children: JSX.Element;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {

  // if (!accessToken) { 
  //   window.location = "#/" as unknown as Location;
  // }

  return children;
};