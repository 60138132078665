import { AxiosResponse } from "axios";
import api from "./api";
import { DetailProfile, MeetingList, UserProfile } from "./types";

export const getResume = (meetingId: string): Promise<AxiosResponse> => {
    return api.get(`/api/v1/dashboard/resume?meeting_id=${meetingId}`)
}

export const getResumeHistory = (meetingId: string, page: number): Promise<AxiosResponse> => {
    return api.get(`/api/v1/dashboard/resume/history?meeting_id=${meetingId}&page=${page}`)
}

export const getResumeSpeeches = (meetingId: string): Promise<AxiosResponse> => {
    return api.get(`/api/v1/dashboard/resume/speeches?meeting_id=${meetingId}`)
}

export const createMagicQuestion = (meetingId: string, question: string): Promise<AxiosResponse> => {
    return api.post('/api/v1/dashboard/question', { question, meeting_id: meetingId })
}

export const listAllMagicQuestion = (meetingId: string): Promise<AxiosResponse> => {
    return api.get(`/api/v1/dashboard/question/${meetingId}/all`)
}

export const getMagicQuestion = (questionId: string): Promise<AxiosResponse> => {
    return api.get(`/api/v1/dashboard/question?question_id=${questionId}`)
}

export const listAutomations = async (): Promise<AxiosResponse> => {
    return api.get('/api/v1/dashboard/automation');
}

export const getAutomationOptions = async (): Promise<AxiosResponse> => {
    return api.get('/api/v1/dashboard/automation/options');
}

export const createAutomation = async (data: any): Promise<AxiosResponse> => {
    return api.post('/api/v1/dashboard/automation', data);
}

export const deleteAutomation = async (automationId: string): Promise<AxiosResponse> => {
    return api.delete(`/api/v1/dashboard/automation?automation_id=${automationId}`);
}

export const getMeetings = async (page = 0, page_size = 9): Promise<MeetingList> => {
  try {
       // Faz a chamada à API e aguarda a resposta
       const response: AxiosResponse = await api.get(`/api/v1/dashboard/meetings-list?page=${page}&page_size=${page_size}`);

       // Extrai os dados necessários da resposta
       const data = response.data;
   
       // Mapeia os dados para o formato MeetingList
       const meetingList: MeetingList = {
           page: data.page,
           page_size: data.page_size,
           total: data.total,
           total_pages: data.total_pages,
           items: data.items.map((item: any) => ({
               id: item.id,          
               title: item.title,
               date: item.created_at,       
               code: item.code,
               participants: item.participants,
               platform: item.platform
           })),
       };
   
       // Retorna o MeetingList formatado
       return meetingList;
  } catch (error) { 
    console.error('Error fetching meetings:', error)

    return {
        page: 0,
        page_size: 0,
        total: 0,
        total_pages: 0,
        items: [],
    }
  }
};

export const getInfoUser = async ():Promise<UserProfile | undefined> => {
    try {
        const response: AxiosResponse = await api.get(`/api/v1/account/me`)
        const data = response.data;

        const userProfile: UserProfile = {
            public_id: data.public_id,
            subscription: data.subscription || "free",
            name: data.name,
            email: data.email,
            cadastral_info: data.cadastral_info || undefined
        }

        return userProfile

    } catch (error) {
        console.error('Error fetching user info:', error)
    }

    return
    
}

export const getPaymentLink = async (plan_id: string):Promise<AxiosResponse> => {
    return api.get(`/api/v1/payment/link?plan_id=${plan_id}`)
}

export const getResumeNoAuth = (meetingId: string): Promise<AxiosResponse> => {
    return api.get(`/meeting/${meetingId}/transcript`)
}

export const createMagicQuestionNoAuth = (meetingId: string, question: string): Promise<AxiosResponse> => {
    return api.post('/question', { question, meeting_id: meetingId})
}

export const getMagicQuestionNoAuth = (questionId: string): Promise<AxiosResponse> => {
    return api.get(`/question?question_id=${questionId}`)
}


export const createMentalMap = (meetingId: string, context?: string): Promise<AxiosResponse> => {
    return api.get(`/api/v1/dashboard/resume/mental-map?meeting_id=${meetingId}&context=${context || ''}`, {
        responseType: 'blob'
    })
}

export const loginLink = (email: string): Promise<AxiosResponse> => {
    return api.post('/api/v1/account/link_login', { email: email })
}

export const saveUserInfos = (details: DetailProfile): Promise<AxiosResponse> => {
    return api.post('/api/v1/account/me/info', {  ...details })
}
