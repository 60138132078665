import React, { useState } from 'react';
import { Paper, IconButton, InputBase, CircularProgress, Snackbar, Alert, AlertColor } from '@mui/material';
import { AutoFixHigh, EmailOutlined } from '@mui/icons-material';
import { loginLink } from 'utils/services';



const MagicLink: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [emailToSend, setEmailToSend] = useState('')
    const [open, setOpen] = useState(false);
    const [msg, setMsg] = useState('')
    const [severity, setSeverity] = useState<AlertColor>()

    const handleFormLink = () => {
        setIsLoading(true)
        loginLink(emailToSend).then(() => {
            setMsg('Link gerado com sucesso, verifique sua caixa de entrada.')
            setSeverity('success')
        }).catch(e => {
            setMsg('Não foi possível gerar o link de login, verifique se o plugin está instalado e ativado em seu navegador.')
            setSeverity('error')
        }).finally(() => {
            setIsLoading(false)
            setOpen(true)
        })
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <Paper
            component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
        >
            <EmailOutlined style={{ color: 'rgba(0, 0, 0, 0.4)' }} />
            
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="seu@email.com"
                inputProps={{ 'aria-label': '' }}
                value={emailToSend}
                onChange={(e) => setEmailToSend(e.target.value)}
            />

            <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" type='submit' onClick={() => { handleFormLink() }}>
                {!isLoading && <AutoFixHigh style={{ color: '#004c8f' }} />}
                {isLoading && <CircularProgress sx={{ width: '24px !important', height: '24px !important' }} />}
            </IconButton>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={open}
                onClose={handleClose}
                key={'topright'}>

                <Alert
                    onClose={handleClose}
                    severity={severity}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {msg}
                </Alert>
            </Snackbar>
        </Paper>
    );
};

export default MagicLink;
