
import React, { useEffect, useState } from "react";
import { Modal, Box, TextField, Button, Typography, CircularProgress } from "@mui/material";
import { DetailProfile, UserProfile } from "utils/types";
import InputMask from "react-input-mask";
import JobAutocomplete from "components/JobSelect";

interface InfoUserModalProps {
    open: boolean;
    handleClose: Function;
    handleSave: Function;
    data: UserProfile | undefined;
    isLoading: boolean;
}

const InfoUserModal = (props: InfoUserModalProps) => {
    const [data, setData] = useState<DetailProfile|undefined>(props.data?.cadastral_info);

    const handleChange = (e: any) => {
       const { name, value } = e.target;
        setData((prev: any) => ({ ...prev, [name]: value }));
    };

    const handleAutocomplete = (value: any) => {
        setData((prev: any) => ({ ...prev, profession: value }));
        console.log(value)
    };


    useEffect(() => {
        if(props.data?.cadastral_info !== undefined) {
            setData(props.data?.cadastral_info)
        }
    }, [props.data?.cadastral_info])

    return (
        <Modal disableEnforceFocus open={props.open} onClose={() => props.handleClose()}>
            <Box
                component="form"
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    maxWidth: 360,
                    width: '100%',
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    padding: 2,
                    borderRadius: 2,

                }}
            >
                <Typography variant="h6" gutterBottom>
                    Informações
                </Typography>
                <Typography variant="caption" gutterBottom>
                    Para melhorarmos a sua experiência e fornecemos resumos mais precisos, informe as informações abaixo:
                </Typography>
                <InputMask
                    mask="(99) 99999-9999"
                    value={data?.phone}
                    onChange={handleChange}
                >
                    {(inputProps) => <TextField {...inputProps} fullWidth margin="normal" label="Telefone" name="phone" required />}
                </InputMask>
                <JobAutocomplete profession={data?.profession}  onSelect={handleAutocomplete}/>
                {/* <TextField
                    fullWidth
                    margin="normal"
                    label="Profissão"
                    name="profession"
                    value={data?.profession}
                    onChange={handleChange}
                    required
                /> */}
                <TextField
                    fullWidth
                    margin="normal"
                    label="Para quê você quer transcrever reuniões?"
                    name="work_description"
                    multiline
                    rows={3}
                    value={data?.work_description}
                    onChange={handleChange}
                    placeholder="Seja específico. Nós iremos considerar esta informação nas suas perguntas mágicas."
                />
                <Button
                    disabled={props.isLoading}
                    // type="submit"
                    variant="contained"
                    sx={{ mt: 2 }}
                    fullWidth
                    onClick={() => props.handleSave(data)}
                >
                    { props.isLoading && <CircularProgress size={20} sx={{marginRight: '8px'}} />}
                    Salvar
                </Button>
            </Box>
        </Modal>
    );
};

export default InfoUserModal;